import React from 'react'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  gray?: boolean
  paddingClassName?: string
}

const Box: React.FC<Props> = ({
  children,
  className,
  gray,
  paddingClassName,
  ...other
}) => {
  return (
    <div
      className={
        'rounded-sm border border-gray-bg-2' +
        (paddingClassName ? ` ${paddingClassName}` : ' px-4 py-3') +
        (gray ? ' bg-gray-bg' : '') +
        (className ? ` ${className}` : '')
      }
      {...other}
    >
      {children}
    </div>
  )
}

export default Box
